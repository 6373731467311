import { Canvas } from '@react-three/fiber'
import { OrbitControls,   } from '@react-three/drei'
import { useRef, useState, useEffect } from 'react';
// import { PerspectiveCamera } from 'three';
import * as THREE from 'three';
function CurvePercent(props) {
    const mesh = useRef()
    return (
        <mesh
            {...props}
            ref={mesh}
            scale={0.4}>
            <torusGeometry args={props.argsGeometry}/>
            <meshStandardMaterial color={props.color_bg}
                wireframe={props.wireframe}
                side={THREE.DoubleSide}
                metalness={0.3}
                roughness={0.2}
            />
        </mesh>
    )
}


// function CameraHelper() {
//     const camera = new PerspectiveCamera(60, 1, 1, 3);
//     return (
//         <group position={[0, 0, 8]}>
//             <cameraHelper args={[camera]} />
//         </group>
//     )
// }


export default function BestStreakDays(props) {

    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => { window.removeEventListener('resize', handleWindowSizeChange); }
    }, []);

    const isMobile = width <= 768;

    return (
        <Canvas gl={{ preserveDrawingBuffer: true }} camera={{ position: [0, 0, 8], fov: 60, zoom: isMobile ? 1.7 : 2.2 }}>

            <ambientLight />
            <pointLight position={[0, 0, 3]} intensity={1} />

            <group position={[0, -1.5, 0]}>
                {/* <group position={[-1.4, 0, 0]}>
                    <Text
                        characters="MEJORACHDÍS"
                        position={[0, 0, 0]}
                        scale={[3, 3, 3]}
                        color="white" // default
                        anchorX="middle" // default
                        anchorY="middle" // default
                    >{"MEJOR RACHA DÍAS"}</Text>
                </group>
                <group position={[-0.5, 1, 0]}>
                    <Text
                        characters="0123456789"
                        position={[0.5, 0, 0]}
                        scale={[9, 9, 9]}
                        color="white" // default
                    >{props.data.bestStreak}</Text>
                </group> */}
                <CurvePercent color_bg={props.color_bg} wireframe={true} argsGeometry={[7, 2, 8, 12, 3.14]} />
                <group rotation={[0, Math.PI / 180 * 180, 0]}>
                    {/* 1 - 3.14 */}
                    <CurvePercent color_bg={props.color_fill} wireframe={false} argsGeometry={[7, 2.1, 8, 12, Math.PI/100*props.data.bestStreak]} />
                </group>
            </group>
            <OrbitControls makeDefault />
            {/* <CameraHelper /> */}
        </Canvas>
    )
}