import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import logo from "./pylapaz_white.png"
import { Grid } from '@mui/material';
function Copyright() {
  return (
    <div>

      <Typography variant="h3" color="white" sx={{ display: { xs: 'none', md: 'block' } }} >
        <Link href="https://twitter.com/PythonLaPaz" color="#fff" target="_blank">{" #100diasdepython"}</Link>
      </Typography>
      <Typography variant="h6" color="white" sx={{ display: { xs: 'block', md: 'none' } }}>
        <Link href="https://twitter.com/PythonLaPaz" color="#fff" target="_blank">{" #100diasdepython"}</Link>
      </Typography>
    </div>
  );
}

export default function StickyFooter() {
  const [width, setWidth] = React.useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => { window.removeEventListener('resize', handleWindowSizeChange); }
  }, []);
  const isMobile = width <= 768;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: isMobile ? '23vh' : '15vh',
      }}
    >
      <CssBaseline />
      <Box
        component="footer"
        sx={{
          mt: 'auto',
          backgroundColor: "transparent"
        }}
      >
        <Grid container sx={{ px: 3 }}
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="flex-end">
          <Grid item>
            <Box
              component="img"
              sx={{
                height: 233,
                maxHeight: { xs: 64, md: 128 },
              }}
              alt="Python La Paz"
              src={logo}
            />
          </Grid>
          <Grid item>
            <Copyright />
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}