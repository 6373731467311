import { Canvas, useFrame,  } from '@react-three/fiber'
import { OrbitControls,  } from '@react-three/drei'
import { useRef, useState, useEffect } from 'react';
function CirclePoints(props) {
    const mesh = useRef()
    useFrame((state, delta) => {
        if (props.canAnimate === true) {
            mesh.current.rotation.z += (props.velocity === undefined ? 0.004 : props.velocity);
        }
    })
    return (
        <mesh
            {...props}
            ref={mesh}
            scale={0.4}>
            <torusGeometry args={props.argsGeometry} />
            <meshStandardMaterial color={props.color_bg}
                wireframe={props.wireframe}
                side={2}
                metalness={0.3}
                roughness={0.2}
            />
        </mesh>
    )
}


export default function BestWeekPoints(props) {

    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => { window.removeEventListener('resize', handleWindowSizeChange); }
    }, []);

    const isMobile = width <= 768;
    let points= Math.PI * 2 / 35 * props.data.bestWeek.points;
    if (props.data.bestWeek.week === 14){
        points= Math.PI * 2 / 45 * props.data.bestWeek.points;
    }
    return (
        <Canvas gl={{ preserveDrawingBuffer: true }} camera={{ position: [0, 0, 8], fov: 60, zoom: isMobile ? 1.9 : 2.5 }}>

            <ambientLight />
            <pointLight position={[1, 0, 3]} intensity={1} />

            <group position={[0, 0, 0]}>
                {/* <group position={[-1.4, -2, 0]}>
                    <Text
                        characters="MEJORSAN0123456789"
                        position={[0, 0, 0]}
                        scale={[3, 3, 3]}
                        color="white" // default
                        anchorX="middle" // default
                        anchorY="middle" // default
                    >{`MEJOR SEMANA ${props.data.bestWeek.week}`}</Text>
                </group>
                <group position={[-0.5, 0, 0]}>
                    <Text
                        characters="0123456789"
                        position={[0.5, 0, 0]}
                        scale={[9, 9, 9]}
                        color="white" // default
                    >{props.data.bestWeek.points}</Text>
                </group>
                <group position={[2, 0, 0]}>
                    <Text
                        characters="PUNTOS"
                        position={[0.5, 0, 0]}
                        scale={[4, 4, 4]}
                        color="white" // default
                    >{"PUNTOS"}</Text>
                </group> */}
                <CirclePoints
                    color_bg={props.color_bg}
                    wireframe={true}
                    canAnimate={true}
                    velocity={-0.004}
                    argsGeometry={[3.3, 0.9, 8, 12, Math.PI * 2/ 35 * 35]}
                />
                <group rotation={[0, Math.PI / 180 * 180, 0]}>
                    {/* 1 - 3.14 */}
                    <CirclePoints color_bg={props.color_fill} 
                    wireframe={false} 
                    canAnimate={true}
                    velocity={-0.008}
                    argsGeometry={[3.3, 1, 8, 12, points]} />
                </group>
            </group>
            <OrbitControls makeDefault />
            {/* <CameraHelper /> */}

        </Canvas>
    )
}