import { Canvas, useFrame,  } from '@react-three/fiber'
import { OrbitControls, Text,  } from '@react-three/drei'
import { useRef, useState, useEffect } from 'react';
// import { PerspectiveCamera } from 'three';

function BoxActivity(props) {
    const mesh = useRef()
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    // Subscribe this component to the render-loop, rotate the mesh every frame
    useFrame((state, delta) => {
        mesh.current.rotation.x += 0.01;
        mesh.current.rotation.y += 0.01;
        })
    return (
        <mesh
            {...props}
            ref={mesh}
            scale={active ? 0.5 : 0.4}
            onClick={(event) => setActive(!active)}
            onPointerOver={(event) => setHover(true)}
            onPointerOut={(event) => setHover(false)}>
            <boxGeometry args={[1, 1, 1]} />
            <meshStandardMaterial color={hovered ? props.hover_color : props.color}
            wireframe={props.wireframe}
            metalness={0.3}
             />
        </mesh>
    )
}


// function CameraHelper() {
//     const camera = new PerspectiveCamera(60, 1, 1, 3);
//     return (
//         <group position={[0, 0, 8]}>
//             <cameraHelper args={[camera]} />
//         </group>
//     )
// }


export default function Score(props) {

    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => { window.removeEventListener('resize', handleWindowSizeChange); }
    }, []);

    const isMobile = width <= 768;

    let col = 0
    let row = 2
    const spacingX = 0.7
    const spacingY = 0.6
    const days = []

    props.data.days.forEach((val, index) => {
        if (row % 7 === 0) {
            col += 1;
            row = 0;
        }
        days.push(
            <BoxActivity key={`day${index}`} position={[col * spacingX, row * (-1) * spacingY, 0]}
            color={val.score === 5 ? props.color_success : val.score === 1 ? props.color_error : props.color_none}  hover_color={"white"}
            wireframe={val.score !== 5 && val.score !== 1}
             />
        )
        row++;
    })

    return (
        <Canvas gl={{ preserveDrawingBuffer: true }} camera={{ position: [0, 0, 8], fov: 60, zoom: isMobile ? 1 : 2 }}>

            <ambientLight />
            <pointLight position={[1, 0, 3]} intensity={1}  />

            <group position={[-4.5, 2, 0]}>
                <Text
                    characters="LUN"
                    position={[-0.5, 0, 0]}
                    scale={[3, 3, 3]}
                    color="white" // default
                    anchorX="right" // default
                    anchorY="middle" // default
                >{"LUN"}</Text>
                <Text
                    characters="JUE"
                    position={[-0.5, -1.85, 0]}
                    scale={[3, 3, 3]}
                    color="white" // default
                    anchorX="right" // default
                    anchorY="middle" // default
                >{"JUE"}</Text>
                <Text
                    characters="DOM"
                    position={[-0.5, -3.65, 0]}
                    scale={[3, 3, 3]}
                    color="white" // default
                    anchorX="right" // default
                    anchorY="middle" // default
                >{"DOM"}</Text>
                {days}
            </group>
            <OrbitControls makeDefault />
            {/* <CameraHelper /> */}

        </Canvas>
    )
}