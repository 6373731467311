import * as React from 'react';
import { ThemeProvider, CssBaseline, Grid, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Fab from '@mui/material/Fab';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { Search, Download, Share, Close } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import "./App.css";
import { appTheme } from "./theme";
import Score from "./Score";
import StickyFooter from "./StickyFooter";
import BestStreakDays from './BestStreakDays';
import BestWeekPoints from './BestWeekPoints';
function App() {
  const defaultUser = {
    "username": "",
    "days": [
      { "day": 1, "score": null }, { "day": 2, "score": null }, { "day": 3, "score": null }, { "day": 4, "score": null }, { "day": 5, "score": null }, { "day": 6, "score": null }, { "day": 7, "score": null }, { "day": 8, "score": null }, { "day": 9, "score": null }, { "day": 10, "score": null },
      { "day": 11, "score": null }, { "day": 12, "score": null }, { "day": 13, "score": null }, { "day": 14, "score": null }, { "day": 15, "score": null }, { "day": 16, "score": null }, { "day": 17, "score": null }, { "day": 18, "score": null }, { "day": 19, "score": null }, { "day": 20, "score": null },
      { "day": 21, "score": null }, { "day": 22, "score": null }, { "day": 23, "score": null }, { "day": 24, "score": null }, { "day": 25, "score": null }, { "day": 26, "score": null }, { "day": 27, "score": null }, { "day": 28, "score": null }, { "day": 29, "score": null }, { "day": 30, "score": null },
      { "day": 31, "score": null }, { "day": 32, "score": null }, { "day": 33, "score": null }, { "day": 34, "score": null }, { "day": 35, "score": null }, { "day": 36, "score": null }, { "day": 37, "score": null }, { "day": 38, "score": null }, { "day": 39, "score": null }, { "day": 40, "score": null },
      { "day": 41, "score": null }, { "day": 42, "score": null }, { "day": 43, "score": null }, { "day": 44, "score": null }, { "day": 45, "score": null }, { "day": 46, "score": null }, { "day": 47, "score": null }, { "day": 48, "score": null }, { "day": 49, "score": null }, { "day": 50, "score": null },
      { "day": 51, "score": null }, { "day": 52, "score": null }, { "day": 53, "score": null }, { "day": 54, "score": null }, { "day": 55, "score": null }, { "day": 56, "score": null }, { "day": 57, "score": null }, { "day": 58, "score": null }, { "day": 59, "score": null }, { "day": 60, "score": null },
      { "day": 61, "score": null }, { "day": 62, "score": null }, { "day": 63, "score": null }, { "day": 64, "score": null }, { "day": 65, "score": null }, { "day": 66, "score": null }, { "day": 67, "score": null }, { "day": 68, "score": null }, { "day": 69, "score": null }, { "day": 70, "score": null },
      { "day": 71, "score": null }, { "day": 72, "score": null }, { "day": 73, "score": null }, { "day": 74, "score": null }, { "day": 75, "score": null }, { "day": 76, "score": null }, { "day": 77, "score": null }, { "day": 78, "score": null }, { "day": 79, "score": null }, { "day": 80, "score": null },
      { "day": 81, "score": null }, { "day": 82, "score": null }, { "day": 83, "score": null }, { "day": 84, "score": null }, { "day": 85, "score": null }, { "day": 86, "score": null }, { "day": 87, "score": null }, { "day": 88, "score": null }, { "day": 89, "score": null }, { "day": 90, "score": null },
      { "day": 91, "score": null }, { "day": 92, "score": null }, { "day": 93, "score": null }, { "day": 94, "score": null }, { "day": 95, "score": null }, { "day": 96, "score": null }, { "day": 97, "score": null }, { "day": 98, "score": null }, { "day": 99, "score": null }, { "day": 100, "score": null },
    ],
    "bestStreak": 0,
    "bestWeek": { "week": 0, "points": 0 },
    "total": 0
  }
  const [username, setUsername] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [userdata, setUserdata] = React.useState(defaultUser);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = (prop) => (event) => {
    setUsername(event.target.value);
  };
  const searchAPI = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetch('/api/stats?' + new URLSearchParams({ "username": username }), {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json"
      },
    }).then((response) => {
      setLoading(false)
      if (!response.ok) {
        setUserdata(defaultUser);
        setMessage("No se encontró al usuario");
        setOpen(true);
        throw new Error('Network response was not OK');
      } else {
        return response.json()
      }
    }).then((data) => {
      setUserdata(data)
      setOpen(false)
    }).catch(function (err) {
      setUserdata(defaultUser);
      setLoading(false)
      console.log(err)
    });
  }

  const handleMouseDownUsername = (event) => {
    event.preventDefault();
    searchAPI();
  };
  const handleClickSearchUsername = () => {
    searchAPI();
  };

  const downloadImage = () => {
    html2canvas(document.querySelector("#root"), {
      ignoreElements: function (element) {
        if (element.classList.contains('button_ignore')) {
          return true;
        }
      }
    }).then(canvas => {
      var link = document.createElement('a');
      link.download = `${username}.png`;
      link.href = canvas.toDataURL()
      link.click();
    });
  }
  const shareImage = () => {
    html2canvas(document.querySelector("#root"), {
      ignoreElements: function (element) {
        if (element.classList.contains('button_ignore')) {
          return true;
        }
      }
    }).then(canvas => {
      fetch(canvas.toDataURL()).then(res => res.blob()).then(blob => {
        // let url = window.URL.createObjectURL(blob);
        const file = new File([blob], `${username}.png`, { type: 'image/png' });
        navigator.share({ text: 'Participe en los #100diadepython organizado por @PythonLaPaz mira mi performance 🐍😎', files: [file] });
      })
    });
  }

  return (
    <ThemeProvider theme={appTheme}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={<React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}>
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>}
      />
      <Box className='bg-gradient' sx={{ p: 3 }}>
        <CssBaseline enableColorScheme />
        <Stack direction="row-reverse" spacing={2}>

          <Fab className='button_ignore' color="primary" onClick={downloadImage} style={{ background: "transparent" }}>
            <Download color='primary' />
          </Fab>
          <Fab className='button_ignore' color="primary" onClick={shareImage} style={{ background: "transparent" }}>
            <Share color='primary' />
          </Fab>

        </Stack>
        <Stack>
          <form onSubmit={handleMouseDownUsername}>
            <FormControl variant="standard" fullWidth className='button_ignore' >
              <InputLabel htmlFor="username"  >Usuario de Discord</InputLabel>
              <Input
                color="primary"
                id="username"
                type={'text'}
                value={username}
                onChange={handleChange('username')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className='button_ignore'
                      aria-label="search"
                      onClick={handleClickSearchUsername}
                      onMouseDown={handleMouseDownUsername}
                      edge="end"
                      color="primary"
                    >
                      {loading ? (<CircularProgress size={20} />)
                        : <Search />
                      }


                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        </Stack>
        <br />

        <Grid container
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Grid item sm={12} md={12}>
            {userdata.username !== "" ?
              <Box>
                <Typography align="center" variant="h3" component="div" sx={{ display: { xs: 'none', md: 'block' } }} color="primary">
                  {`PUNTAJE TOTAL  `}
                  <Typography align="center" variant="h3" component="span" color="secondary">{userdata.total}</Typography>
                </Typography>
                <Typography align="center" variant="h5" component="div" sx={{ display: { xs: 'block', md: 'none' } }} color="primary">
                  {`PUNTAJE TOTAL  `}
                  <Typography align="center" variant="h5" component="span" color="secondary">{userdata.total}</Typography>
                </Typography>
              </Box>
              :
              <Box>
                <Typography align="center" variant="h4" component="span" sx={{ display: { xs: 'none', md: 'block' } }} color="primary">{`RESULTADOS`}</Typography>
                <Typography align="center" variant="h5" component="span" sx={{ display: { xs: 'block', md: 'none' } }} color="primary">{`RESULTADOS`}</Typography>
              </Box>
            }
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item sm={12} md={12}>
            <Box style={{ position: "relative", width: "100%", height: "30vh" }}>
              <Score data={userdata} color_success={"#349FA8"} color_error={"#C12D4A"} color_none={"white"} />
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box style={{ position: "relative", width: "100%", height: "25vh" }}>
              <BestStreakDays data={userdata} color_bg={"#C12D4A"} color_fill={"#349FA8"} />
            </Box>
            <Box >
              <Typography align="center" variant="h4" component="div" color="primary">
                {`PARTICIPÉ `}
                <Typography align="center" variant="h4" component="span" color="secondary">{userdata.bestStreak}</Typography>
                {` DÍAS SEGUIDOS`}
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box style={{ position: "relative", width: "100%", height: "25vh" }}>
              <BestWeekPoints data={userdata} color_bg={"#C12D4A"} color_fill={"#349FA8"} />
              <Typography align="center" variant="h4" component="div" color="primary">
                {`MEJOR SEMANA  `}
                <Typography align="center" variant="h4" component="span" color="secondary">
                  {userdata.bestWeek.week}
                </Typography>
                <Typography align="center" variant="h4" component="div" color="secondary">
                  {userdata.bestWeek.points}
                  <Typography align="center" variant="h4" component="span" color="primary">
                    {` PUNTOS`}
                  </Typography>
                </Typography>

              </Typography>
            </Box>
          </Grid>
        </Grid>
        <StickyFooter />
      </Box>
    </ThemeProvider >
  )
}

export default App;
