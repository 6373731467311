import { createTheme } from "@mui/material/styles";
export const appTheme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: "#349FA8",
        },
        secondary: {
            main: "#C12D4A",
        },
        text: {
            primary: "#349FA8",
            secondary: "#C12D4A",
        },
    },
    color: {
        primary: {
            main: "#349FA8",
        },
        secondary: {
            main: "#C12D4A",
        }
    },
    
});